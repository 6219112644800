/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

type PathT = {
  url?: string
  title?: string
  children: string
}

type Props = {
  paths: PathT[]
}

const BreadCrumb = ({ paths }: Props) => {
  const home: PathT = { title: "Home", url: "/", children: "🏡" }
  return (
    <div sx={{ display: "flex", "& > *": { paddingRight: 2 } }}>
      {[home, ...paths].map(({ url, title, children }, index, arr) => (
        <Fragment key={children}>
          {index > 0 && <span>❯</span>}
          {index === arr.length - 1 ? (
            <span sx={{ color: "disabled" }}>{children}</span>
          ) : (
            <a href={url} title={title ?? children}>
              {children}
            </a>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default BreadCrumb
export type { Props }
