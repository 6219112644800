/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useState, useRef } from "react"

import { Parallax } from "@react-spring/parallax"
import Layout from "~components/Layout/layout"

import Seo from "~components/Seo/seo"

import Content from "~components/Layout/content"
import Inner from "~components/Layout/inner"
import Breadcrumb from "~components/Breadcrumb"

import { BackgroundPlain as Background } from "~compounds/Background"
import ProjectPage from "~translations/projectPage.mdx"
import PieSteam from "~compounds/PieSteam"
import { useGetProject } from "~src/integrations"
import type { ProjectItem } from "~src/integrations"
import Svg from "~components/Icon/svg"

const styles = {
  padding: [3, 4, 4, 5],
  rightAreaDisplay: ["none", "none", "none", "block"],
  overlayAreaDisplay: ["block", "block", "block", "none"],
  projectWidth: ["100%", "100%", "100%", "50%"],
  layoutAlign: ["center", "center", "center", "flex-start"],
}
const css = {
  halfArea: {
    overflow: "auto",
    maxHeight: "100%",
    paddingRight: styles.padding,
    paddingLeft: styles.padding,
    paddingTop: 5,
    paddingBottom: 5,
  },
}

const Bg = ({ showingProject }) => (
  <Fragment>
    <div
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "50%",
        backgroundColor: "white",
        display: styles.rightAreaDisplay,
      }}
    />
    <Background offset={0} factor={1} sx={{ zIndex: showingProject && -1 }} />
  </Fragment>
)

const ProjectList = ({ projects, selectedIndex, onClick }) => (
  <ul>
    {projects.map((item, index) => (
      <li key={item.projectName} sx={{ color: selectedIndex === index ? "primary" : "", "&:hover": { color: "primary" } }}>
        <a onClick={() => onClick(index)} sx={{ color: "inherit" }}>
          {item.projectName}
        </a>
      </li>
    ))}
  </ul>
)

const ProjectDetails = ({ project, colorTitle, color }: { project?: ProjectItem; colorTitle: string; color: string }) =>
  !!project && (
    <Fragment>
      <p sx={{ marginBottom: 0, color: "primary" }}>{project.company}</p>
      <h2 sx={{ marginTop: 0, color: colorTitle }}>{project.systemName}</h2>
      <p sx={{ color }}>{project.description}</p>
      {project.photos.map((photo) => (
        <img key={photo} alt="" src={photo} sx={{ width: "100%", marginBottom: 3, boxShadow: "2px 2px 3px rgb(0 0 0 / 20%)" }} />
      ))}
    </Fragment>
  )

const Project = () => {
  const [apiStatus, getProject] = useGetProject()

  const projects = apiStatus.response || []
  // const projects = _items // apiStatus.response || []
  const [index, setIndex] = useState(-1)
  const ref = useRef<HTMLDivElement>(null)
  const onClick = (index: number) => {
    setIndex(index)
    ref.current.scrollTop = 0
  }

  // TODO: <div sx={{ position: "absolute", top: 0, left: 0, right: 0, height: 1, backgroundColor: "primary" }} />
  // useEffect(() => {
  //   const timeout = setTimeout(() => setIndex(index > projects.length - 2 ? 0 : index + 1), 10000)
  //   return () => clearTimeout(timeout)
  // }, [index])

  const selectedProject = projects[index]

  const [accessCode, setAccessCode] = useState("")
  const onChangeAccessCode = (e) => {
    const v = e.target.value
    if (v.length >= 6) {
      setAccessCode("")
      getProject(v)
    } else {
      setAccessCode(v)
    }
  }

  return (
    <Layout>
      <Seo title="Our projects" />
      <Parallax pages={1}>
        <Bg showingProject={!!selectedProject} />
        <Content sx={{ justifyContent: styles.layoutAlign, padding: [0, 0, 0, 0] }} speed={0.4} offset={0} factor={1}>
          <Inner sx={{ maxHeight: "100%", overflow: "auto", display: "flex", ">div": { width: styles.projectWidth } }}>
            <div sx={css.halfArea}>
              <div
                sx={{
                  svg: { opacity: 0.3 },
                  position: "absolute",
                  left: styles.projectWidth,
                  top: 0,
                  "& > div": { transform: "translate(-80%, 20%)" },
                }}
              >
                <PieSteam />
              </div>
              <Breadcrumb paths={[{ children: "Our Projects" }]} />
              <ProjectPage />
              {!!projects.length ? (
                <ProjectList projects={projects} selectedIndex={index} onClick={onClick} />
              ) : (
                <Fragment>
                  <p sx={{ variant: "styles.p" }}>
                    Please contact us at <a href="mailto:info@twopies.io">info@twopies.io</a>, or enter your access code to the box below:
                  </p>
                  <input
                    type="password"
                    sx={{ variant: "input" }}
                    value={accessCode}
                    onChange={onChangeAccessCode}
                    disabled={apiStatus.isLoading}
                  />
                  {apiStatus.error && !accessCode && (
                    <p sx={{ color: "tomato", marginTop: 0 }}>{`Incorrect access code. Please contact us`}</p>
                  )}
                </Fragment>
              )}
            </div>
            <div
              ref={ref}
              className="hide-scroll"
              sx={{
                display: styles.rightAreaDisplay,
                ...css.halfArea,
              }}
            >
              <ProjectDetails project={selectedProject} color="gray" colorTitle="icon_darkest" />
            </div>
          </Inner>
        </Content>
      </Parallax>

      <div
        sx={{
          display: styles.overlayAreaDisplay,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          padding: styles.padding,
          backgroundColor: "background",
          overflow: "auto",
          transition: "all ease-in .2s",
          opacity: selectedProject ? 1 : 0,
          zIndex: selectedProject ? 1 : -1,
          transform: selectedProject ? "none" : "translateY(10%)",
        }}
      >
        <ProjectDetails project={selectedProject} color="#ccc" colorTitle="icon_brightest" />

        <Svg
          icon="cross"
          width={0}
          left="50%"
          top=""
          color="white"
          sx={{
            width: "3rem",
            bottom: "2rem",
            position: "fixed",
            transform: "translateX(-50%)",
            backgroundColor: "icon_darker",
            padding: 12,
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => onClick(-1)}
        />
      </div>
    </Layout>
  )
}

export default Project
