import { useState, useCallback } from "react"

// const endpoint = `https://twopies.io/.netlify/functions/projects`
const endpoint = `/.netlify/functions/projects`

type ErrorResponse = {
  code: 400 | 404
  message: string
}

type ApiStatus<TResponse> = {
  numberOfCall: number
  isLoading: boolean
  response: TResponse | null
  error: string | null
}

type ProjectResponse = {
  code: 200
  data: {
    projectName: string
    systemName: string
    company: string
    description: string
    photos: string[]
  }[]
}

type ProjectItem = ProjectResponse["data"][number]

type UseApi<TResponse, TRequest> = () => [ApiStatus<TResponse>, (req: TRequest) => Promise<void>]

const initStatus = { numberOfCall: 0, isLoading: false, response: null, error: null }

const useGetProject: UseApi<ProjectItem[], string> = () => {
  const [status, setStatus] = useState<ApiStatus<ProjectItem[]>>(initStatus)

  const api = useCallback(async (accessCode: string) => {
    try {
      setStatus((s) => ({ ...initStatus, numberOfCall: s.numberOfCall + 1, isLoading: true }))
      const res = await fetch(endpoint, { method: "POST", body: JSON.stringify({ accessCode }) })
      const body = (await res.json()) as ProjectResponse | ErrorResponse
      if (body.code === 200) setStatus((s) => ({ ...s, isLoading: false, response: body.data }))
      else throw new Error(body.message)
    } catch (error) {
      setStatus((s) => ({ ...s, isLoading: false, error: error.message ?? "Sorry, please try again later." }))
    }
  }, [])

  return [status, api]
}

export { useGetProject }
export type { ProjectItem }
